import { BlogList } from '@/BlogList';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';
import { graphql } from 'gatsby';

const Blogs: Page = ({ data }) => {
    const heroImage = data.allFile.edges[0].node.childImageSharp.gatsbyImageData;
    const blogs = data.allMdx.nodes;
    return (
        <main>
            <DefaultLayout
                title="Blog"
                heroImage={heroImage}
                heroText="Read through some of our blog articles and learn more about the services we provide. You can also find some information on Holistic health and other related information!"
                // heroText={data.mdx.frontmatter.heroText}
            >
                <Seo
                    title="Blogs | Bloom La Vie Health"
                    description="Upgrade Your HealthCare. Elevate Your Health Personalized, holistic primary care. Unlimited visits to the physician and healthcare team in person, virtual,"
                />
                <BlogList blogPage blogs={blogs} />
            </DefaultLayout>
        </main>
    );
};

export default Blogs;

export const query = graphql`
    query MdxBlogPost {
        allMdx(
            filter: { fields: { sourceInstanceName: { eq: "blogs" } } }
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            nodes {
                frontmatter {
                    title
                    slug
                    date(formatString: "MMMM DD, YYYY")
                    images {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE)
                        }
                    }
                }
                body
                excerpt(truncate: true)
            }
        }
        allFile(filter: { relativePath: { eq: "blogs.jpg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                }
            }
        }
    }
`;
