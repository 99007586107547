import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
/* eslint-disable import/no-unresolved */
import { Link } from 'gatsby';

interface Props {
    blogs: any;
}

const StyledBlogList = styled.div`
    padding: 1em;
    section {
        h2 {
            margin: 0.5em 0em 0em;
            font-size: 23px;
        }
        p {
            padding: 0.5em 0em 0.5em;
        }
        /* each panel except main one */
        .small-panels {
            margin: 3em 0em;
            display: flex;
            flex-direction: column;
        }
        button {
            margin: 1em 0em 4em;
        }
    }

    .small-picture,
    .big-picture {
        border-radius: 16px;
    }
    @media ${device.tablet} {
        max-width: 700px;
        margin: 0 auto;
    }
    @media ${device.laptop} {
        max-width: 1280px;
        min-width: 1160px;
        margin: 0 auto;
        .big-picture {
            max-width: 508px;
            height: 344px;
        }
        section {
            justify-content: space-between;
            display: flex;
        }
        .main-panel {
            max-width: 508px;
        }
        .remaining-panel {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0em auto 4em;
            max-width: 1100px;
            img {
                height: 508px;
            }
            .remaining-text {
                max-width: 500px;
                position: relative;
                left: 2em;
                h2 {
                    margin-bottom: em;
                }

                button {
                    margin-top: 3em;
                }
            }
        }
        .small-panel {
            display: flex;
            justify-content: space-between;
            width: 608px;
            margin: -3em 0em 10em;
            height: 253px;
            .text {
                max-width: 390px;
                margin-left: 30px;
                button {
                    margin-left: 4em;
                }
                > div {
                    margin-top: -0.5em;
                    display: flex;
                    align-items: baseline;
                }
            }
        }
        .small-picture {
            height: 209px;
            width: 209px;
            img {
                height: 209px;
                width: 209px;
            }
        }
    }
    @media ${device.laptopL} {
        .main-panel {
            max-width: 450px;
        }
        .big-picture {
            max-width: 608px;
            height: 344px;
        }
        max-width: 1192px;
        margin: 0 auto;
    }
`;

export const BlogList: React.FC<Props> = ({ blogPage, blogs }) => {
    const selectBlogs = blogs.slice(blogs.length - 3);
    const remainingBlogs = blogs.slice(3);
    return (
        <StyledBlogList>
            <section>
                <div className="main-panel">
                    <GatsbyImage
                        className="big-picture"
                        alt="main image"
                        image={blogs[0].frontmatter.images[0].childImageSharp.gatsbyImageData}
                    />
                    <h2>{blogs[0].frontmatter.title}</h2>
                    <p>{blogs[0].excerpt}</p>
                    <span>{blogs[0].frontmatter.date}</span>

                    {blogPage ? (
                        <Link to={blogs[0].frontmatter.slug}>
                            <Button color="blue-light">Read more</Button>
                        </Link>
                    ) : (
                        <Link to={blogs[0].frontmatter.slug}>
                            <Button color="blue-light">Listen to Podcast</Button>
                        </Link>
                    )}
                </div>
                <div className="small-panels">
                    {selectBlogs.map(blog => {
                        return (
                            <>
                                <div className="small-panel">
                                    <GatsbyImage
                                        className="small-picture"
                                        alt="main image"
                                        image={
                                            blog.frontmatter.images[0].childImageSharp
                                                .gatsbyImageData
                                        }
                                    />
                                    <div className="text">
                                        <h2>{blog.frontmatter.title}</h2>
                                        <p>{blog.excerpt}</p>
                                        <div>
                                            <span>{blog.frontmatter.date}</span>
                                            {!blogPage &&
                                                blog.frontmatter.title ===
                                                    'The Six Pillars of Lifestyle Medicine - Nutrition' && (
                                                    <Link to={blog.frontmatter.slug}>
                                                        <Button color="blue-light">
                                                            Read more
                                                        </Button>
                                                    </Link>
                                                )}
                                            {!blogPage &&
                                                blog.frontmatter.title !==
                                                    'The Six Pillars of Lifestyle Medicine - Nutrition' && (
                                                    <Link to={blog.frontmatter.slug}>
                                                        <Button color="blue-light">
                                                            Listen to Podcast
                                                        </Button>
                                                    </Link>
                                                )}
                                            {blogPage && (
                                                <Link to={blog.frontmatter.slug}>
                                                    <Button color="blue-light">Read more</Button>
                                                </Link>
                                            )}{' '}
                                        </div>
                                    </div>
                                </div>
                            </>
                        );
                    })}
                </div>
            </section>
            {blogPage &&
                remainingBlogs.map(blog => {
                    return (
                        <div key={blog.frontmatter.title} className="remaining-panel">
                            <GatsbyImage
                                className="big-picture"
                                alt="main image"
                                image={blog.frontmatter.images[0].childImageSharp.gatsbyImageData}
                            />
                            <div className="remaining-text">
                                <h2>{blog.frontmatter.title}</h2>
                                <p>{blog.excerpt}</p>
                                <div>
                                    <span>{blog.frontmatter.date}</span>
                                    {blogPage ? (
                                        <Link to={blog.frontmatter.slug}>
                                            <Button color="blue-light">Read more</Button>
                                        </Link>
                                    ) : (
                                        <Link to={blog.frontmatter.slug}>
                                            <Button color="blue-light">Listen to Podcast</Button>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
        </StyledBlogList>
    );
};
